'use client'

import 'react-loading-skeleton/dist/skeleton.css'
import isOnAnyTeam from '@/utils/isOnAnyTeam'
import { AppShell, Burger } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as Avatar from '@radix-ui/react-avatar'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import { type Session } from 'next-auth'
import { signOut } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { useContext } from 'react'
import { AppContext } from './AppProvider'
import AutoComplete from './AutoComplete'
import ExternalLink from './ExternalLink'

const NAV_ITEMS = [
  {
    href: '/',
    label: 'dashboard',
  },
  {
    href: '/team',
    label: 'team',
  },
  {
    href: '/pipeline-calendar',
    label: 'pipeline calendar',
  },
  {
    href: '/pipeline-suggestions',
    label: 'pipeline suggestions',
  },
  {
    href: '/expiring-companies',
    label: 'expiring companies',
  },
  {
    href: '/expired-companies',
    label: 'expired companies',
  },
  {
    href: '/recently-contacted-companies',
    label: 'recently contacted companies',
  },
  {
    href: '/tracked-emails',
    label: 'tracked emails',
  },
]

const userInitials = (user?: Session['user']) => {
  const username = user?.name ?? 'Not Applicable'
  const [first, last] = username.split(' ')
  return `${first[0]}${last[0]}`
}

export default function Shell({ children }: { children: React.ReactNode }) {
  const { user, avatarSrc, impersonateSalesforceId, isAdmin } =
    useContext(AppContext)
  const router = useRouter()
  const pathname = usePathname()
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure()
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure()

  const toggleNav = () => {
    if (mobileOpened) {
      toggleMobile()
    } else if (desktopOpened) {
      toggleDesktop()
    }
  }

  const logout = () => {
    signOut({
      callbackUrl: '/?forceLogout=true',
    })
  }

  const handleImpersonateSubmit = async (
    name: string,
    salesforceId: string
  ) => {
    if (mobileOpened) {
      toggleMobile()
    }

    if (/search/i.test(salesforceId)) {
      router.push(`/search?query=${salesforceId.split('-').pop()}`)
      return
    }

    router.push(`/company/${salesforceId}`)
  }

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      padding="md"
      withBorder={false}
    >
      <AppShell.Header className="shadow-lg">
        <div className="bg-navbar h-full">
          <div className="mx-auto px-4 flex items-center h-full gap-4">
            <div>
              <Burger
                opened={mobileOpened}
                onClick={toggleMobile}
                hiddenFrom="sm"
                size="sm"
                color="white"
              />
              <Burger
                opened={desktopOpened}
                onClick={toggleDesktop}
                visibleFrom="sm"
                size="sm"
                color="white"
              />
            </div>
            <Link href="/">
              <Image
                src="/battery-charger-logo.png"
                alt="Battery Charger Logo"
                width={203}
                height={40}
                priority
                className="mb-[8px]"
              />
            </Link>
            <div className="ml-auto flex items-center gap-4">
              <AutoComplete
                className="hidden lg:block w-[15rem]"
                searchType="company"
                onSubmit={handleImpersonateSubmit}
                keyAccessor="id"
                valueAccessor="id"
                labelAccessor="name"
                inputPlaceholder="Search"
                variant="search"
              />
              <DropdownMenu.Root modal={false}>
                <DropdownMenu.Trigger asChild>
                  <Avatar.Root className="inline-flex h-[45px] w-[45px] select-none items-center justify-center overflow-hidden rounded-full align-middle ml-auto hover:cursor-pointer">
                    <Avatar.Image
                      className="h-full w-full rounded-[inherit] object-cover"
                      src={avatarSrc}
                      alt={user?.name ?? 'user avatar'}
                    />
                    <Avatar.Fallback className="text-gray-500 leading-1 flex h-full w-full items-center justify-center bg-neutral-50 text-[15px] font-medium">
                      {userInitials(user)}
                    </Avatar.Fallback>
                  </Avatar.Root>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.Content
                    sideOffset={3}
                    className="min-w-[8rem] bg-navbar rounded-md p-1 shadow-lg z-[99999]"
                  >
                    <DropdownMenu.Item
                      className="text-white font-semibold tracker-wide text-sm hover:cursor-pointer hover:bg-battery-triangle hover:focus-visible:outline-none px-4 py-1 rounded-smf flex w-full"
                      asChild
                    >
                      <Link href="/profile">Profile</Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item
                      className="text-white font-semibold tracker-wide text-sm hover:cursor-pointer hover:bg-battery-triangle hover:focus-visible:outline-none px-4 py-1 rounded-smf flex w-full"
                      asChild
                    >
                      <ExternalLink href="https://chromewebstore.google.com/detail/battery-charger-20/copihdhkgfbmcikcnhogkfngomnohpfj?hl=en&authuser=0&pli=1">
                        Chrome Extension
                      </ExternalLink>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item
                      className="text-white font-semibold tracker-wide text-sm hover:cursor-pointer hover:bg-battery-triangle hover:focus-visible:outline-none px-4 py-1 rounded-sm"
                      onClick={logout}
                    >
                      Logout
                    </DropdownMenu.Item>
                    <DropdownMenu.Arrow className="fill-battery-triangle" />
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </div>
          </div>
        </div>
      </AppShell.Header>
      <AppShell.Navbar
        p="md"
        styles={{
          navbar: {
            backgroundColor: 'rgb(0, 42, 58)',
            borderTop: '2px solid rgb(44, 113, 185)',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRight: 'none',
          },
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="my-4 lg:my-0">
            <AutoComplete
              className="lg:hidden"
              searchType="company"
              onSubmit={handleImpersonateSubmit}
              keyAccessor="id"
              valueAccessor="id"
              labelAccessor="name"
              inputPlaceholder="Search"
              variant="search"
            />
          </div>
          {NAV_ITEMS.map(({ href, label }) => {
            return (
              <Link
                key={href}
                onClick={toggleNav}
                className={clsx(
                  'text-white font-semibold tracking-wide text-lg hover:text-battery-hover capitalize',
                  {
                    'underline underline-offset-4': pathname === href,
                    hidden:
                      label == 'team' &&
                      !isOnAnyTeam(
                        impersonateSalesforceId || user?.salesforceId
                      ),
                  }
                )}
                href={href}
              >
                {label}
              </Link>
            )
          })}
          {isAdmin && (
            <Link
              onClick={toggleNav}
              className={clsx(
                'text-white font-semibold tracking-wide text-lg hover:text-battery-hover capitalize',
                {
                  'underline underline-offset-4': pathname === '/sigma',
                }
              )}
              href="/sigma"
            >
              Sigma
            </Link>
          )}
        </div>
      </AppShell.Navbar>
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  )
}
