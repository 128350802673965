'use client'

import { updateCompany } from '@/app/(root)/dashboardActions'
import { CompanySearchResult, DashboardCompany } from '@/types'
import { Button, Loader, NativeSelect } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { useEffect, useState } from 'react'
import { useFormState, useFormStatus } from 'react-dom'
import AutoComplete from '../AutoComplete'

function SubmitButton() {
  const { pending } = useFormStatus()

  return (
    <Button
      color="rgb(0, 42, 58)"
      type="submit"
      disabled={pending}
      classNames={{
        label: 'flex gap-2',
      }}
    >
      Save{' '}
      {pending ? <Loader color="rgb(0, 42, 58)" type="dots" size={14} /> : ''}
    </Button>
  )
}

export default function EditDashboardCompanyModal({
  context,
  id,
  innerProps: { company },
}: ContextModalProps<{
  company: {
    priority: DashboardCompany['priority']
    name: DashboardCompany['name']
    salesforceId: DashboardCompany['salesforceId']
    id: DashboardCompany['id']
    coverageStatus: DashboardCompany['coverageStatus']
    addOnCandidateId: DashboardCompany['addOnCandidateId']
    addOnCandidateName: DashboardCompany['addOnCandidateName']
    conflictCompanyId: DashboardCompany['conflictCompanyId']
    conflictCompanyName: DashboardCompany['conflictCompanyName']
  }
}>) {
  const [{ done, success, message, title, errors }, formAction] = useFormState(
    updateCompany,
    {
      done: false,
      success: false,
      message: '',
      title: '',
      errors: {},
    }
  )
  const [shownToast, setShownToast] = useState(false)
  const [coverageStatus, setCoverageStatus] = useState(
    company.coverageStatus || ''
  )
  const [candidateTo, setCandidateTo] = useState(company.addOnCandidateId || '')
  const [candidateToName, setCandidateToName] = useState(
    company.addOnCandidateName || ''
  )
  const [conflictCompany, setConflictCompany] = useState(
    company.conflictCompanyId || ''
  )
  const [conflictCompanyName, setConflictCompanyName] = useState(
    company.conflictCompanyName || ''
  )

  useEffect(() => {
    if (done) {
      if (success) {
        context.closeModal(id)
      }

      if (!shownToast) {
        notifications.show({
          title: title,
          message: message,
          color: success ? 'green' : 'red',
        })

        setShownToast(true)
      }
    }
  }, [company.name, context, done, id, message, shownToast, success, title])

  const handleAddOnSubmit = async (name: string, salesforceId: string) => {
    setCandidateTo(salesforceId)
    setCandidateToName(name)
  }

  const handleConflictSubmit = async (name: string, salesforceId: string) => {
    setConflictCompany(salesforceId)
    setConflictCompanyName(name)
  }

  return (
    <form action={formAction}>
      <input type="hidden" name="companyName" value={company.name} />
      <input type="hidden" name="salesforceId" value={company.salesforceId} />
      <input type="hidden" name="orgId" value={company.id} />
      <input type="hidden" name="currentPriority" value={company.priority} />
      <input
        type="hidden"
        name="currentCoverageStatus"
        value={company.coverageStatus}
      />

      <input
        type="hidden"
        name="currentConflictCompany"
        value={company.conflictCompanyId || ''}
      />
      <input
        type="hidden"
        name="conflictCompany"
        value={conflictCompany || ''}
      />
      <input
        type="hidden"
        name="conflictCompanyName"
        value={conflictCompanyName || ''}
      />

      <input
        type="hidden"
        name="currentCandidateTo"
        value={company.addOnCandidateId || ''}
      />
      <input type="hidden" name="candidateTo" value={candidateTo || ''} />
      <input
        type="hidden"
        name="candidateToName"
        value={candidateToName || ''}
      />

      <div className="flex flex-col gap-4">
        <NativeSelect
          name="priority"
          label="Priority"
          defaultValue={company.priority.toLowerCase()}
          data={[
            {
              label: 'Top',
              value: 'top',
            },
            {
              label: 'High',
              value: 'high',
            },
            {
              label: 'Medium',
              value: 'medium',
            },
            {
              label: 'Low',
              value: 'low',
            },
          ]}
        />

        <NativeSelect
          name="coverageStatus"
          label="Coverage Status"
          defaultValue={company.coverageStatus}
          onChange={(e) => setCoverageStatus(e.currentTarget.value)}
          data={[
            {
              label: 'Open',
              value: 'Open',
            },
            {
              label: 'Covered',
              value: 'Covered',
            },
            {
              label: 'Covered - Add-on candidate',
              value: 'Covered - Add-on candidate',
            },
            {
              label: 'Conflict',
              value: 'Conflict',
            },
            {
              label: 'Dead',
              value: 'Dead',
            },
            {
              label: 'Portfolio - Active',
              value: 'Portfolio - Active',
            },
            {
              label: 'Portfolio - Inactive',
              value: 'Portfolio - Inactive',
            },
          ]}
        />

        {coverageStatus === 'Dead' && (
          <NativeSelect
            name="deadCategory"
            label="Dead Category"
            withAsterisk={coverageStatus === 'Dead'}
            error={errors.deadCategory}
            data={[
              {
                label: 'Select',
                value: '',
              },
              {
                label: 'Acquired by strategic',
                value: 'Acquired by strategic',
              },
              {
                label: 'Acquired by private equity',
                value: 'Acquired by private equity',
              },
              {
                label: 'Too much raised',
                value: 'Too much raised',
              },
              {
                label: 'Out of business',
                value: 'Out of business',
              },
              {
                label: 'Duplicate',
                value: 'Duplicate',
              },
              {
                label: 'Public',
                value: 'Public',
              },
              {
                label: 'Never will be a Battery deal',
                value: 'Never will be a Battery deal',
              },
            ]}
          />
        )}

        {coverageStatus === 'Covered - Add-on candidate' && (
          <AutoComplete<CompanySearchResult>
            searchType="company"
            onSubmit={handleAddOnSubmit}
            autoFocus={true}
            defaultValue={company.addOnCandidateName || ''}
            inputLabel="Candidate To"
            inputPlaceholder="Search"
            keyAccessor="salesforceId"
            valueAccessor="salesforceId"
            labelAccessor="name"
            withAsterisk={coverageStatus === 'Covered - Add-on candidate'}
            error={errors.candidateTo}
          />
        )}

        {coverageStatus === 'Conflict' && (
          <AutoComplete<CompanySearchResult>
            searchType="company"
            onSubmit={handleConflictSubmit}
            autoFocus={true}
            defaultValue={company.conflictCompanyName || ''}
            inputLabel="Conflict Company"
            inputPlaceholder="Search"
            keyAccessor="salesforceId"
            valueAccessor="salesforceId"
            labelAccessor="name"
            withAsterisk={coverageStatus === 'Conflict'}
            error={errors.conflictCompany}
          />
        )}

        {coverageStatus === 'Open' && (
          <p>
            Selecting &quot;Open&quot; will immediately change the Salesforce
            coverage status to &quot;Open&quot; and will make this company
            eligible for anyone to reach out to per the Salesforce rule.
          </p>
        )}
      </div>

      <div className="flex gap-2 mt-4 justify-end">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <SubmitButton />
      </div>
    </form>
  )
}
